export const environment = {
  production: true,
  apiUrl: 'https://api.medical-chambers.com',
  publicUrl: 'https://site-admin.medical-chambers.com',
  clientSettings: {
    authority: 'https://api.medical-chambers.com',
    client_id: 'admin',
    scope: 'openid profile email admin role',
  },
};
